








































import { defineComponent, computed } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

export default defineComponent({
  name: "BaseDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    closeAction: {
      // if a simple close button should be shown
      type: Boolean,
      default: false,
    },
    persistent: {
      // "persistent" property of <v-dialog>
      type: Boolean,
      default: false,
    },
    scrollable: {
      // "scrollable" property of <v-dialog>
      type: Boolean,
      default: false,
    },
    overlay: {
      // "overlay" property of <v-dialog>
      type: Boolean,
      default: true,
    },
    fullscreen: {
      // "fullscreen" property of <v-dialog>
      type: Boolean,
      default: false,
    },
    transition: {
      // "transition" property of <v-dialog>
      type: String,
      default: undefined,
    },
    closeOnEsc: {
      // whether the dialog shall be closed when <Esc> is pressed
      type: Boolean,
      default: false,
    },
    mw0: {
      // set maximum width to mw-0 (and set appropriate fullscreen breakpoint)
      type: Boolean,
      default: false,
    },
    mw1: {
      // set maximum width to mw-1 (and set appropriate fullscreen breakpoint)
      type: Boolean,
      default: false,
    },
    mw2: {
      // set maximum width to mw-2 (and set appropriate fullscreen breakpoint)
      type: Boolean,
      default: false,
    },
    mw3: {
      // set maximum width to mw-3 (and set appropriate fullscreen breakpoint)
      type: Boolean,
      default: false,
    },
    mw4: {
      // set maximum width to mw-4 (and set appropriate fullscreen breakpoint)
      type: Boolean,
      default: false,
    },
  },

  setup(
    props,
    {
      emit,
      root: {
        $vuetify: { breakpoint },
      },
    }
  ) {
    const { t } = useI18n()
    const dialog = computed({
      get() {
        return props.value
      },
      set(newVal) {
        emit("input", newVal)
      },
    })

    const close = () => {
      emit("input", false)
    }

    const onEscPressed = () => {
      if (props.closeOnEsc) {
        close()
      }
    }

    const isFullscreen = computed(() => {
      if (props.fullscreen) return true

      if (props.mw0) return breakpoint.xs
      else if (props.mw1) return breakpoint.smAndDown
      else if (props.mw2) return breakpoint.smAndDown
      else if (props.mw3) return breakpoint.smAndDown
      else if (props.mw4) return breakpoint.smAndDown
      else return breakpoint.smAndDown
    })

    const maxWidth = computed(() => {
      if (props.mw0) return "750px"
      else if (props.mw1) return "1000px"
      else if (props.mw2) return "1250px"
      else if (props.mw3) return "1500px"
      else if (props.mw4) return "1750px"
      else return "100%"
    })

    return {
      t,
      dialog,
      isFullscreen,
      onEscPressed,
      close,
      maxWidth,
    }
  },
})
