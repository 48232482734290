




import { computed, defineComponent, PropType } from "@vue/composition-api"
import { UseCached } from "@/_store/storeHelper"

export default defineComponent({
  name: "StoreObjectDisplay",

  props: {
    useStore: {
      type: Function as PropType<
        <T extends Record<string, unknown>>() => UseCached<T>
      >,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
  },

  setup(props) {
    const { cached } = props.useStore()

    const name = computed(() => {
      const item = cached.value?.find((it) => it[props.itemValue] === props.id)
      return item?.[props.itemText]
    })

    return { name }
  },
})
